import {Component} from 'doz'

export default class AppHeader extends Component {

    onMount() {
        const mainEl = document.getElementsByTagName('main')[0];
        window.onscroll = () => {
            if (window.pageYOffset > this.ref.header.offsetTop) {
                this.ref.header.classList.add('sticky');
                mainEl.style.paddingTop = '100px';
            } else {
                this.ref.header.classList.remove('sticky');
                mainEl.style.paddingTop = '0';
            }
        };
    }

    template(h) {
        //language=html
        return h`
            <style> 
                header {
                    background: #fff;
                    color: #000;
                    transition: all 300ms;
                    display: flex;
                    width: 100%;
                    /*height: 120px;*/
                    align-items: center;
                    justify-content: center;
                    text-align: left;
                    padding: 20px;
                    position: relative;
                    z-index: 3;
                }
                
                .sticky {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 90px;
                }
                
                .sticky h1 {
                    transition: all 300ms;
                    font-size: 18px;
                    margin: 0;
                } 
                
                .sticky h2 {
                    transition: all 300ms;
                    font-size: 16px;
                    margin: 0;
                } 
                
                .col-left {
                    flex: 1;
                }
                
                .col-right {
                    width: 100%;
                    max-width: max-content;
                }
                
                h1 {
                    font-size:2vw;
                    margin: 0;
                    font-weight: bold;
                    background: -webkit-linear-gradient(#008ed0, #00a3fd);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                
                h2 {
                    font-size:1vw;
                    margin: 0;
                }
                
                h3 {
                    font-size:0.8vw;
                    margin: 0;
                }

                .charts-link {
                    overflow: auto;
                    overflow-y: hidden;
                    padding: 8px;
                    white-space: nowrap;
                }
                
                .charts-link a, .charts-link a:link {
                    text-decoration: none;
                    color: #00a3fd;
                    border: 1px solid #999;
                    border-radius: 10px;
                    padding: 6px;
                    font-size: 14px;
                }
                
                .charts-link a:hover {
                    background: #00a3fd;
                    border-color: #00a3fd;
                    color: #fff;
                }

                .charts-link .router-link-active {
                    background: #00a3fd!important;
                    border-color: #00a3fd!important;
                    color: #fff!important;
                }
                
                @media (hover: none) {
                    .charts-link a:hover {
                        background: #00a3fd;
                        border-color: #00a3fd;
                        color: #fff;
                    }
                }
                
                @media only screen and (max-width: 1024px) {
                    
                    .col-left {
                        flex: none;
                        margin-bottom: 10px;
                    }
                    
                    h1, h2, h3 {
                        margin: 0;
                        text-align: center;
                    }
                    
                    h1 {
                        font-size: 18px;
                    }
                    
                    h2 {
                        font-size: 15px;
                    }
                    
                    h3 {
                        font-size: 12px;
                    }
                    
                    header {
                        flex-direction: column;
                    }
                    
                }
                
                @media only screen and (max-width: 600px) {

                    h1 {
                        font-weight: bold;
                    }

                    h2 {
                        font-size: 12px;
                    }

                    h3 {
                        font-size: 10px;
                    }

                    header {
                        /*height: 110px;*/
                        padding: 8px;
                    }
                    .col-left {
                        margin-bottom: 0;
                    }
                    .col-right {
                        display: none;
                    }
                    /*
                    .sticky h1 {
                        font-size: 14px;
                    } 
                    
                    .sticky h2 {
                        font-size: 12px;
                    } 

                    .sticky {
                        height: 80px;
                    }
                    */
                    
                }
                
            </style>
    
            <header d-ref="header">
                <div class="col-left">
                    <h1>${this.props.title}</h1>  
                    <h2><strong>${this.props.subTitle}</strong></h2>  
                    <h3>${this.props.description}</h3>
                </div>
                <div class="col-right">
                    <slot name="col-right"/>
                </div>
            </header>
        `
    }

    changeChart(type) {
        this.getStore('home').chartType = type;
    }

    onMountAsync() {
        this.router.bindLink();
        this.router.activeLink();
    }

}
