import {component} from 'doz'
import ArrowTrend from './arrow-trend'

component('x-track-details', {

    id: 'trackDetails',
    store: 'trackDetails',

    hide() {
        if (this.mountInstance) {
            this.mountInstance.destroy();
            this.mountInstance = null;
        }
    },

    show(record) {

        if (!record || !record.artist || !record.title || this.mountInstance) return;

        this.props = record;

        location.hash = '#track-details'

        this.mountInstance = this.mount(
            //language=HTML
            this.h`
            <style> 
                x-overlay {
                    /*opacity: 0.9;*/
                }
                
                .overlay-content {
                    display: flex;
                    height: 100%;
                    flex-flow: row wrap;
                    align-content: center;
                    color: #fff;
                    margin: auto;
                    position: relative;
                    z-index: 1;
                    overflow: auto;
                    padding: 20px;
                }
                
                .overlay-content img{
                    width: 400px;
                }

                .overlay-content .artist {
                    margin-bottom: 0
                }
                
                .overlay-content .title {
                    margin-top: 0;
                }
                
                .header {
                    width: 100%;
                    min-height: 200px;
                    margin-bottom: 20px;
                }
                
                .header h1{
                    font-size: 30px;
                }
                
                .header h1, .header h2{
                    margin: 20px;
                }
                
                .details {
                    width: 100%;
                    max-width: 375px;
                    /*max-width: 400px;*/
                    border-spacing: 0;
                    /*margin: auto;*/
                    border: 0;
                    display: block;
                    padding: 0;
                }
                
                .details td {
                    border-bottom: 1px solid rgba(255,255,255,.5);
                    padding: 15px;
                    text-align: left;
                    width: 50%;
                }
                
                .details td:last-child {
                    text-align: right;
                }
                
                .backdrop {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(0,0,0,1) 70%); 
                }
                
                .credits {
                    padding: 40px;
                    text-align: center;
                    width: 100%;
                }
                
                .credits a {
                    color: white;
                }
                
                .credits a:hover {
                    color: #ccc;
                }
                
                .trend {
                    margin-left: 0;
                    margin-right: 0;
                }
                
                .sub-header {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                @media only screen and (max-height: 414px) {
                    .overlay-content {
                        align-content: normal;
                    }
                }
            </style>
            <x-overlay d:ondestroy="${() => this.callMyDestroy()}" duration="300ms" animOpen="slideInRight" animClose="slideOutLeft"> 
                <x-artwork-background url="${this.props.artwork}" opacity="1" blur="10px"/>
                <div class="backdrop"></div>
                <div class="overlay-content">
                    <div class="header"> 
                        <div class="sub-header">
                            <img style="border-radius: 4px; border: 2px solid #fff; width: 200px; height: 200px;" src="${this.props.artwork}">
                        </div>
                        <h1 class="artist">${this.props.artist}</h1>
                        <h2 class="title">${this.props.title}</h2>
                        <h2 class="authors">${this.props.authors}</h2>
                        <div class="label placeholder-element">Label: ${this.props.labelBrand}</div>
<!--                        <div class="label placeholder-element" d-show="${!!this.props.licenseDistributor}">Licenza / Distribuzione: ${this.props.licenseDistributor}</div>-->
                    </div>
                    <div style="display: flex; align-items: center; justify-content: center; width: 100%;">
                        <table class="details" style="border-top: 1px solid rgba(255,255,255,.5)"> 
                            <tr style="font-size: 32px;" d-show="${this.props.spins > 0}"> 
                                <td>Posizione</td>
                                <td style="text-align:right">${this.props.position}</td>
                                <td rowSpan="4">
                                    <div class="trend"> 
                                        <${ArrowTrend} style="visibility: ${this.props.spins ? 'visible': 'hidden'}" value="${this.props.trend}"/>
                                    </div>
                                </td>
                            </tr> 
                            <tr> 
                                <td>Punteggio</td>
                                <td>${this.props.score}</td>
                            </tr>  
                            <tr> 
                                <td>Passaggi</td>
                                <td>${this.props.spins}</td>
                            </tr>   
                            <tr> 
                                <td>Emittenti</td>
                                <td>${this.props.broadcasterCount}</td>
                            </tr> 
                        </table>
                    </div>
                </div>
            </x-overlay>
        `);

    },

    callMyDestroy() {
        this.mountInstance = null;
        location.hash = ''
    }
});