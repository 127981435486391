import {component} from 'doz'

component('x-artwork-background', {
    props: {
        blur: '10px',
        opacity: 0.3
    },
    template(h) {
        return h` 
            <style> 
                bg-img {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    background-size: cover;
                    background-position: top;
                    background-repeat: no-repeat;
                    /*filter: blur(${this.props.blur});
                    webkit-filter: blur(${this.props.blur});
                    opacity: ${this.props.opacity};*/
                }
            </style>
            <bg-img style="background-image: url('${this.props.url}')"/>
        `
    }
});