import {component} from 'doz'

component('x-overlay', {

    props: {
        animOpen: 'slideInDown',
        animClose: 'slideOutUp',
        duration: '300ms'
    },

    template(h) {
        return h`
            <style>
                :component {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(0,0,0,0.8);
                    z-index: 99999;
                    padding: 0;
                    margin: 0;
                    opacity: 0.9;
                }
            </style>
            <dz-empty></dz-empty>
        `;
    },

    onMountAsync() {
        //return;
        if (this.props.mode === 'hidden') return;

        const rootElement = this.getHTMLElement();
        const opt = {
            duration: this.props.duration
        };

        window.onhashchange = () => {
            if (location.hash === '')
                this.close()
        }

        //console.log(rootElement)

        this.animate(rootElement, this.props.animOpen, opt);
        // Add to tag container
        rootElement
            .addEventListener('click', (e) => {
                this.close()
            });
    },

    /*
    onUnmount() {
        console.log('OVERLAY UNMOUNT')
    },

    onDestroy() {
        console.log('OVERLAY DESTROY')
    },
    */

    close() {
        const rootElement = this.getHTMLElement();
        const opt = {
            duration: this.props.duration
        };

        this.animate(rootElement, this.props.animClose, opt, () => {
            rootElement.style.display = 'none';
            this.remove();
            //console.log(this.app.mainComponent)
            setTimeout(() => {
                this.app.mainComponent.fixChartContainerHeight();
            }, 1000)

        });
    },

    remove() {
        if (this.props.mode === 'hidden') return;
        // If is mounted
        if (this.parent && this.parent.tag === 'dz-mount') {
            setTimeout(() => {
                this.parent.destroy();
            });
        } else {
            this.destroy();
        }
    }
});