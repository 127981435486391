import {Component} from 'doz'
import OverlayElement from './overlay-element'

export default class AddThis extends Component {

    props = {
        linkToShare: '' || window.location.href,
        descriptionToShare: ''
    }
    template(h) {
        return h`
            <style> 
                :global .at-expanding-share-button-toggle {
                    display: none;
                }
                
                .button {
                    background: transparent;
                    border: none;
                    font-size: 20px;
                    cursor: pointer;
                    padding: 0;
                    margin: 0;
                    color: #000;
                    /*margin-left: 16px;*/
                    outline: none;
                }
                
                .button:hover {
                    color: #3d56b8;
                }
            </style>
    
            <button class="button" title="Condividi su" onclick="${this.triggerShare}"><i class="far fa-share-alt"></i></button>
        `
    }

    triggerShare() {

        let linkToShare = encodeURIComponent(this.props.linkToShare);
        let descriptionToShare = encodeURIComponent(this.props.descriptionToShare);

        let facebookUrl = `https://www.facebook.com/sharer.php?u=${linkToShare}`;
        let twitterUrl = `https://twitter.com/share?url=${linkToShare}&text=${descriptionToShare}`;
        let whatsAppUrl = `https://api.whatsapp.com/send?text=${descriptionToShare}%20${linkToShare}`;
        //let linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${linkToShare}&title=${descriptionToShare}`;
        let pinterestUrl = `https://pinterest.com/pin/create/button/?url=${linkToShare}&description=${descriptionToShare}`;

        //language=html
        this.app.mount(this.h`
            <style> 
                .social-button {
                    background: none;
                    border: none;
                    cursor: pointer;
                    padding: 0;
                    color: #fff;
                }
                
                .social-button-overlay-content {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    flex-flow: row wrap;
                    align-content: center;
                    margin-top: -60px;
                }
                
                .social-button-overlay-content h2 {
                    text-align: center;
                    width: 100%;
                    color: #fff;
                }
                
                .social-button-overlay-content .social-button {
                    font-size: 44px;
                    margin: 10px;
                }
            </style>
            <${OverlayElement} title="share"> 
                <div class="social-button-overlay-content">
                    <h2>Condividi su</h2>
                    <a title="Condividi su Facebook" class="social-button" href="${facebookUrl}" target="_blank">
                        <span class="fab fa-facebook-f"></span>
                    </a>
                    <a title="Condividi su Twitter" class="social-button" href="${twitterUrl}" target="_blank">
                        <span class="fab fa-twitter"></span>
                    </a>
                    <a title="Condividi su WhatsApp" class="social-button" href="${whatsAppUrl}" target="_blank">
                        <span class="fab fa-whatsapp"></span>
                    </a>
                    <a title="Condividi su Pinterest" class="social-button" href="${pinterestUrl}" target="_blank">
                        <span class="fab fa-pinterest"></span>
                    </a>
                </div>
            </>
        `);
    }
}

// import {Component} from 'doz'
//
// export default class AddThis extends Component {
//
//     template(h) {
//         return h`
//             <style>
//                 :global .at-expanding-share-button-toggle {
//                     display: none;
//                 }
//
//                 button {
//                     background: transparent;
//                     border: none;
//                     font-size: 20px;
//                     cursor: pointer;
//                     padding: 0;
//                     margin: 0;
//                     /*margin-left: 16px;*/
//                     outline: none;
//                 }
//
//                 button:hover {
//                     color: #3d56b8;
//                 }
//             </style>
//
//             <button onclick="${this.triggerShare}"><i class="far fa-share-alt"></i></button>
//         `
//     }
//
//     triggerShare() {
//         document.querySelector('.at-expanding-share-button-toggle a').click()
//     }
// }
