import {Component} from 'doz'

class Arrow extends Component {
    template(h) {
        //language=HTML
        return h`
            <div class="arrow-item" style="border-bottom-color: ${this.props.color};display: ${this.props.value >= this.props.max ? 'block' : 'none'} ;"/>
        `
    }
}

export default class ArrowTrend extends Component {

    template(h) {
        let val = this.props.value;
        return h`
            <style> 
                :component {
                    text-align: center;
                    width: 35px;
                    display: block;
                }
                
                .small {
                    font-size: 15px;
                    /*text-transform: uppercase;*/
                    font-weight: bold;
                    display: block;
                    margin-top: 10px;
                }
                
                @media only screen and (max-width: 800px) {
                    :component {
                        width: 20px;
                    }
                    
                    .small {
                        display: none;
                    }
                }

            </style>
            <${Arrow} color="#e91e63" value="${val}" max="${4}"/>
            <${Arrow} color="#760e96" value="${val}" max="${3}"/>
            <${Arrow} color="#b40e11" value="${val}" max="${2}"/>
            <${Arrow} color="#ef7d50" value="${val}" max="${1}"/>
            <${Arrow} color="#efa750" value="${val}" max="${0}"/>
            <small class="small">trend</small>
        `
    }

}