import {Component} from 'doz'
import AppHeader from '../../app-header'
import AppLogo from '../../app-logo'
import RealTime from '../../real-time'
import ChartTracks from '../chart-tracks'
//import galaxy from "../../../galaxy";
//import 'particles.js'
//import particleJSConfig from './particlesjs-config'
import '../../x-track/x-track-details'
import '../../x-artwork-background'
import AddThis from '../../add-this'
//import sjs from "scroll-js";

export default class Earone extends Component {
    props = {
        loading: true
    }

    template(h) {
        //language=HTML
        return h`
            <style>
                .track {
                    background: rgba(255, 255, 255, .90);
                    /*border: 4px solid #af9491;*/
                    border: 2px solid #fff;
                    margin-bottom: 4px;
                    transition: all 300ms;
                }

                .track:hover {
                    background: rgba(0, 0, 0, .90);
                    border: 2px solid #3a66b2;
                    color: #fff;
                }
                
                .artwork {
                    width: 90px;
                    height: 90px;
                    border-radius: 4px;
                    border: 2px solid #fff;
                    display: block;
                }

                .loading {
                    padding: 16px;
                }

                #particles-container canvas {
                    /*opacity: 0.5;*/
                }

                @media only screen and (max-width: 600px) {
                    /*footer {*/
                    /*    display: block;*/
                    /*}*/
                    .track {
                        border-right: 0;
                        border-left: 0;
                    }

                    .track:hover {
                        border-right: 0;
                        border-left: 0;
                    }
                }
            </style>
            <main>
<!--                <div d-ref="particles" id="particles-container" d-ssr-invisible>-->
<!--                    <canvas class="webgl"></canvas>-->
<!--                </div>-->

                <${AppHeader} d:alias="AppHeader" class="app-header" title="EarOne Sanremo Airplay 2024"
                              subTitle="Le più ascoltate in radio"
                              description="Classifica per punteggio da mercoledì 7 febbraio 2024">
                    <div slot="col-right" class="charts-link">
                        <!--a data-router-link href="/earone/campioni">Campioni</a> 
                        <a data-router-link href="/earone/nuove-proposte">Nuove Proposte</a-->
                        <${AddThis} class="add-this-header"/>
                    </div>
                </>
                <div class="chart-container" d-ref="chartContainer">
                    <video style="width: 100%; height: 100%; position: fixed; object-fit: cover; left: 0; top: 0;" autoplay muted loop src="https://admuzzum.mediasender.it/sanremo/background/2020-06.mp4"></video>
                    <div class="loading" d-show="${this.props.loading}">Caricamento...</div>
                    <div d-ref="containerTop"></div>
                    ${this.pageCatcher()}
                    <div d-ref="containerBottom"></div>
                </div>
                <footer>
                    <${AddThis} class="add-this-footer"/>
                    <${RealTime} d:alias="RealTime" class="real-time"/>
                    <${AppLogo} style="flex: 1" d:alias="AppLogo"/>
                </footer>
                <x-track-details/>
            </main>
        `;
    }

    pageCatcher() {
        let page = this.router.param('page');
        switch (page) {
            case 'campioni':
                return this.h`
                    <${ChartTracks} 
                        d:on-loaded="${this.firstLoad}"
                        source-url="https://api.xdevel.com/earone/sanremo/big/radio/?clientId=2d3cb6b69df48fb23a33594ecf9fd0d871f7931d"
                    />`
            case 'nuove-proposte':
                return this.h`
                    <${ChartTracks} 
                        d:on-loaded="${this.firstLoad}"
                        source-url="https://api.xdevel.com/earone/sanremo/new/radio/?clientId=2d3cb6b69df48fb23a33594ecf9fd0d871f7931d"
                    />`

            default:
                return this.h`<div></div>`
        }
    }

    firstLoad() {
        if (!window.SSR) {
            this.props.loading = false;
        }
    }

    scrollLoop() {
        if (this.router.query('autoscroll')) {
            Scroll.scrollIntoView(
                this.ref.containerBottom, this.ref.chartContainer, {
                    duration: this.router.query('autoscroll')
                }).then(() => {
                Scroll.scrollIntoView(this.ref.containerTop, this.ref.chartContainer, {
                    duration: this.router.query('autoscroll')
                }).then(() => {
                    setTimeout(() => {
                        this.scrollLoop()
                    }, 5000)
                })
            })
        }
    }

    onMountAsync() {
        if (!this.router.param('page'))
            this.router.navigate('/earone/campioni');

        if (!window.SSR) {
            ///setTimeout(() => {
                //particlesJS('particles-container', particleJSConfig);
                /*tsParticles.load('particles-container', {
                    particles: {
                        size: {
                            random: true,
                        },
                    },
                    preset: 'seaAnemone'
                });*/
            //galaxy('canvas.webgl');
            // this.animate(this.ref.particles, 'fadeIn');

            /*this.ref.chartContainer.addEventListener('scroll', () => {
                this.app.mainComponent.fixChartContainerHeight();
            })*/

            setTimeout(() => {
                this.scrollLoop()
            }, 3000);

        }
    }
}