import {Component, tag} from 'doz'
import request from '../../helpers/request';
import XTrack from '../x-track/x-track'
let emptyImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Uw8AAn0BfY81YKcAAAAASUVORK5CYII=';
let placeholderItems = [];

/*for (let i = 0; i < 30; i++) {
    placeholderItems.push({
        isPlaceholder: true,
        track: {
            artist: new Date().getTime(),
            title: new Date().getTime() + 1,
            artistPhotoUrl: {
                400: emptyImage
            },
            scoreNow: 0,
            spinsNow: 0
        }
    })
}*/

@tag('chart-tracks')
export default class extends Component {

    props = {
        tracks: [],
    };

    template(h) {
        return h`
            <style> 
                @keyframes placeHolderShimmer {
                  0%{ background-position: -468px 0; }
                  100%{ background-position: 468px 0; }
                }
                
                .placeholder .placeholder-element {
                  animation-duration: 1.8s;
                  animation-fill-mode: forwards;
                  animation-iteration-count: infinite;
                  animation-timing-function: linear;
                  animation-name: placeHolderShimmer;
                  background: #f6f7f8;
                  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
                  background-size: 900px 104px;
                  /*height: 96px;*/
                  position: relative;
                  color: transparent;
                  opacity: 1;
                }
                
                .track {
                    cursor: pointer;
                }

                .placeholder .position {
                    margin-left: 20px;
                }
                
                .placeholder .details {
                    border-color: transparent;
                    color: transparent;
                    -webkit-text-fill-color: inherit;
                }
                
                .placeholder .artwork {
                    visibility: hidden;
                }
                
                .placeholder .artwork-wrapper {
                    border-radius: 4px;
                }
                
            </style>
            <div>
                ${this.props.tracks.map((item, i) => h`
                    <${XTrack} 
                        isPlaceholder="${item.isPlaceholder}"
                        my-key="${i + item.track.artist + item.track.title}"
                        position="${i + 1}"
                        exhibition-day="${item.exhibitionDay}"
                        artist="${item.track.artist}"
                        title="${item.track.title}"
                        labelAuto="${item.track.labelAuto}"
                        label="${item.track.label}"
                        labelBrand="${item.track.labelBrand}"
                        licenseDistributor="${item.track.licenseDistributor}"
                        authors="${item.track.authors}"
                        artwork="${item.track.artistPhotoUrl && item.track.artistPhotoUrl['400'] ? item.track.artistPhotoUrl['400'] : item.track.artistPhotoUrl}"
                        score="${item.scoreNow}"
                        spins="${item.spinsNow}"
                        trend="${item.trend}"
                        broadcasterCount="${item.broadcasterCountNow}"
                    />
                `)}
            </div>
        `
    }

    onMount() {
        if (!this.isSSR()) {
            this.props.tracks = [];//placeholderItems;
            this.loadRequest();
            this.props.forceupdate = true;
        } else {

        }
    }

    loadRequest() {
        request(this.props.sourceUrl)
            .then(result => {
                //this.props.tracks = this.randomize(result.data.result.tracks);
                this.props.tracks = result.data.result.tracks;
                this.getStore('main').extraInfo = result.data.result.extraInfo;
                this.emit('loaded');
            })
            .catch(err => {
                console.error(err);
                setTimeout(() => this.loadRequest(), 1000);
            });
        this.handlerRequest = setTimeout(() => this.loadRequest(), 60000);
    }

    onUnmount() {
        if (this.handlerRequest) {
            clearTimeout(this.handlerRequest);
        }
    }

    onDestroy() {
        if (this.handlerRequest) {
            clearTimeout(this.handlerRequest);
        }
    }
}