import {Component, h} from 'doz'
import ArrowTrend from './arrow-trend'

export default class XTrack extends Component {

    //delayUpdate = 1500;
    propsConvertOnFly = true;
    propsType = {
        score: 'number',
        //position: 'number',
        spins: 'number'
    };

    propsConvert = {
        /*position(value) {
            //console.log('VALUE', value, this.props.artist);
            return this.props.spins === 0 || Number.isNaN(value) ? '-' : value;
        },*/
        score(value) {
            if (isNaN(value)) return '';
            return value != null ? value.toFixed(1) : 0;
        },
        spins(value) {
            return value != null ? value : 0;
        }
    };

    propsListener = {
        myKey() {
            this.ref.track.style.opacity = '1';
            let duration = this.getRandomInt(800, 1200) + 'ms';
            this.animate(this.ref.track, 'backInLeft', {duration}, () => {
            //this.animate(this.ref.track, 'fadeIn', {duration}, () => {
                this.ref.track.style.opacity = '1';
            });

        },
        /*artwork(v) {
            this.loadim(v, () => {
                //this.animate(this.ref.artwork, 'flipInX', {duration: '200ms'});
            });
        }*/
    };

    onBeforeUpdate(changes) {
        if(changes[0].currentPath === 'myKey') {
            let duration = this.getRandomInt(800, 1200) + 'ms';
            this.animate(this.ref.track, 'backOutRight', {duration}, () => {
            //this.animate(this.ref.track, 'fadeOut', {duration}, () => {
                this.ref.track.style.opacity = '0';
            });
        }
    }

    template(h) {
        return h`
            <div d-ref="track" class="track ${this.props.isPlaceholder ? 'placeholder' : ''}" onclick="${this.openDetails}">
                <div class="position placeholder-element" style="visibility: ${this.props.spins === 0 ? 'hidden' : 'visible'}">
                    ${this.props.position} 
                </div>
                <div class="exhibitionDay"> 
                    <span d-show="${this.props.exhibitionDay === 5}">*</span>
                </div>
                <div class="trend"> 
                    <${ArrowTrend} style="visibility: ${this.props.spins ? 'visible': 'hidden'}" value="${this.props.trend}"/>
                </div>
                <div class="artwork-wrapper placeholder-element" style="display: inline">
                    <img d-ref="artwork" loading="lazy" class="artwork" alt="${this.props.artist} - ${this.props.title}" src="${this.props.artwork}">
                </div>
                <div class="song"> 
                    <div class="artist placeholder-element">${this.props.artist}</div>
                    <div class="title placeholder-element">${this.props.title}</div>
                    <div class="label placeholder-element">Label: ${this.props.labelAuto}</div>
<!--                    <div class="label placeholder-element" d-show="${!!this.props.licenseDistributor}">Licenza / Distribuzione: ${this.props.licenseDistributor}</div>-->
                </div>
                <div class="details placeholder-element"> 
                    <div><label>Punteggio</label> <span>${this.props.score === '0.0' ? ' ' : this.props.score}</span></div>
                    <div><label>Passaggi</label> <span>${this.props.spins ? this.props.spins : ' '}</span></div>
                    <div><label>Emittenti</label> <span>${this.props.broadcasterCount ? this.props.broadcasterCount : ' '}</span></div>
                </div>
            </div>
        `
    }

    openDetails() {
        this.getComponentById('trackDetails').show(this.props);
    }
}