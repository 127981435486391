import {Component} from "doz";

export default class extends Component {
    props = {
        animOpen: "fadeIn",
        animClose: "fadeOut",
        duration: "200ms",
        closeOnClick: true,
        _showContainer: false,
        _showBackdrop: false,
        title: "",
        zIndex: 99999,
    };

    template(h) {
        //language=html
        return h`
            <style>
                :component {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: rgba(0, 0, 0, 0.9);
                    z-index: ${this.props.zIndex};
                    padding: 0;
                    margin: 0;
                    /*transition: all 100ms;*/
                    backdrop-filter: ${
                            this.props._showBackdrop ? "blur(6px)" : "none"
                    };
                    -webkit-backdrop-filter: ${
                            this.props._showBackdrop ? "blur(6px)" : "none"
                    };
                }

                .overlay-element-container {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    flex-wrap: wrap;
                    height: 100%;
                    width: 100%;
                }

                .overlay-element-header {
                    width: 100%;
                    /*height: 86px;*/
                    position: relative;
                    display: flex;
                    margin: 24px;
                    justify-content: flex-end;
                }

                .close-button {
                    /*position:absolute;
                    right: 24px;
                    top: 24px;
                    z-index: 100;*/
                    opacity: .8;
                    background: transparent;
                    border: none;
                    color: #fff;
                    font-size: 32px;
                    cursor: pointer;
                    padding: 0;
                }

                .overlay-element-body {
                    overflow: auto;
                    height: calc(100% - 86px - 48px);
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .sidebar-header {
                    width: 100%;
                    height: auto;
                    margin-right: 24px;
                }

                .overlay-element-header .sidebar-header-container {
                    margin: 0;
                }

                @media only screen and (max-width: 576px) {
                    /*.overlay-element-header {
                        height: 45px;
                        margin: 16px;
                    }*/
                    .overlay-element-header .sidebar-header-title {
                        font-size: 12px;
                        height: 12px;
                    }

                    .sidebar-header-logo {
                        width: 100%;
                    }

                    /*
                    .overlay-element-header .sidebar-header-logo {
                        width: 45px;
                        height: 45px;
                    }

                    .overlay-element-body {
                        overflow: auto;
                        height: calc(100% - 45px - 32px);
                        width: 100%;
                        display: flex;
                    }*/

                }
            </style>
            <div class="overlay-element-container" d-show="${
                    this.props._showContainer
            }" d-animate="${{show: "fadeIn"}}">
                <div class="overlay-element-header">
                    <button title="Chiudi" class="close-button" onclick="${() =>
                            this.close()}">
                        <i class="fal fa-times"></i>
                    </button>
                </div>
                <div class="overlay-element-body">
                    <slot/>
                </div>
            </div>
        `;
    }

    onMountAsync() {
        if (this.props.mode === "hidden") return;

        const rootElement = this.getHTMLElement();
        const opt = {
            duration: this.props.duration,
        };

        this.animate(rootElement, this.props.animOpen, opt, () => {
            this.props._showContainer = true;
            //setTimeout(() => {
            //this.props._showBackdrop = true;
            // }, 150)
        });

        if (this.props.closeOnClick) {
            // Add to tag container
            rootElement.addEventListener("click", (e) => {
                this.close();
            });
        }
    }

    /*onUnmount() {
          console.log('OVERLAY UNMOUNT')
      }

      onDestroy() {
          console.log('OVERLAY DESTROY')
      }*/

    close() {
        //this.props._showBackdrop = false;
        //setTimeout(() => {
        const rootElement = this.getHTMLElement();
        const opt = {
            duration: this.props.duration,
        };
        this.animate(rootElement, this.props.animClose, opt, () => {
            rootElement.style.display = "none";
            this.remove();
        });
        //}, 500)
    }

    remove() {
        if (this.props.mode === "hidden") return;
        //console.log(this.parent)
        // distrugge il parente dz-mount
        if (this.parent.parent) this.parent.parent.destroy();
        // If is mounted
        else if (this.parent && this.parent.tag === "dz-mount") {
            setTimeout(() => {
                this.parent.destroy();
            });
        } else {
            this.destroy();
        }
    }
}
