import Doz from 'doz'
import hotLocationReload from 'doz-hot-location-reload'
import metaTag from 'doz-metatag'
import DozRouter from 'doz-router'
import './app.css'
import page404 from './cmp/pages/404'
import isSSR from 'doz-ssr/plugin'
import 'animate.css/animate.compat.css'
import animate from 'animatify'
import loadim from 'loadim'
import './cmp/x-overlay'
import EarOne from './cmp/pages/earone'
import RadioItalia from './cmp/pages/radio-italia'
import TvSorrisiECanzoni from './cmp/pages/tv-sorrisi-e-canzoni'

hotLocationReload(module);

//Doz.use(snap);
Doz.use(isSSR);
Doz.use(metaTag, {
    title: 'Sanremo 2024',
    description: 'Le classifica airplay delle canzoni di Sanremo fornita da EarOne',
    image: 'https://admuzzum.mediasender.it/sanremo/shared.png?' + new Date().getTime(),
    url: 'https://sanremo.earone.it',
    type: 'website'
});

Doz.mixin({
    loadim,
    animate,
    randomize(arr) {
        return arr.sort(function () {
            return .9 - Math.random();
        });
    },
    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
});

new Doz({

    root: '#app',

    store: 'main',

    props: {
        extraInfo: ''
    },

    template(h) {
        //language=HTML
        return h`
            <style>

                :global .at-expanding-share-button-toggle {
                    display: none;
                }

                main {
                    padding: 0;
                }

                .add-this-footer {
                    display: none;
                }
                
                .chart-container {
                    /*padding: 40px 40px 140px;*/
                    padding: 40px;
                    overflow: auto;
                    overflow-x: hidden;
                    height: calc(100vh - 140px);
                    position: relative;
                }

                chart-tracks {
                    position: relative;
                    display: block;
                    z-index: 2;
                }

                .track {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    background: rgba(255, 255, 255, .90);
                    margin-bottom: 4px;
                    padding: 10px 0;
                    color: #000;
                    overflow: hidden;
                    border-radius: 10px;
                    max-width: 968px;
                    margin-left: auto;
                    margin-right: auto;
                }

                .artwork {
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    border: 5px solid #ffffff;
                    display: block;
                }

                .song {
                    margin: 20px;
                    flex: 2;
                }

                .position {
                    text-align: center;
                    font-size: 90px;
                    min-width: 120px;
                    color: #3d56b8;
                    /*font-weight: bold;*/
                    /*background: -webkit-linear-gradient(#a0b2ff, #192a6b);*/
                    background: -webkit-linear-gradient(#008ed0, #00a3fd);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-style: italic;
                }

                .exhibitionDay {
                    text-align: center;
                    font-size: 25px;
                    width: 30px;
                }

                .trend {
                    margin-right: 40px;
                    margin-left: -20px;
                }

                .details {
                    margin: 0 20px;
                    width: 120px;
                    flex: 1;
                    text-align: left;
                    border-left: 4px solid #00a3fd;
                    padding-left: 20px;
                }

                .details label {
                    display: inline-block;
                    width: 80px;
                }


                .details span {
                    font-size: 20px;
                    width: 90px;
                    display: inline-block;
                    font-weight: bold;
                    color: #00a3fd;
                    background: -webkit-linear-gradient(#008ed0, #00a3fd);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .artist {
                    font-size: 26px;
                    font-weight: bold;
                    background: -webkit-linear-gradient(#008ed0, #00a3fd);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }

                .title {
                    font-weight: bold;
                    font-size: 26px;
                    margin-bottom: 10px;
                }

                .label {
                    font-size: 12px;
                }

                .authors {
                    font-size: 12px;
                }

                .extrainfo-container {
                    color: #888;
                    font-size: 10px;
                    width: 100%
                }

                #particles-container {
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                }

                .arrow-item {
                    width: 0;
                    height: 0;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid;
                    margin: auto;
                }

                @media only screen and (max-width: 800px) {
                    .arrow-item {
                        border-left-width: 10px;
                        border-right-width: 10px;
                        border-bottom-width: 10px;
                    }
                }

                @media only screen and (max-width: 414px) {
                    .arrow-item {
                        border-left-width: 8px;
                        border-right-width: 8px;
                        border-bottom-width: 8px;
                    }
                }

                @media only screen and (min-width: 1440px) {
                    header, footer {
                        padding-left: 180px !important;
                        padding-right: 180px !important;
                    }
                }

                @media only screen and (max-width: 800px) {
                    .chart-container {
                        /*padding: 4px 4px 120px;*/
                        padding: 4px;
                    }

                    .track {
                        /*padding: 0;*/
                        border-radius: 0;
                    }

                    .artwork {
                        width: 60px;
                        min-width: 60px;
                        height: 60px;
                        border: 2px solid #fff;
                    }

                    .artist, .title {
                        font-size: 15px;
                    }

                    .position {
                        font-size: 20px;
                        min-width: 30px;
                        margin-left: 20px;
                    }

                    .label {
                        font-size: 12px;
                    }

                    .authors {
                        font-size: 12px;
                    }

                    .details span {
                        font-size: 14px;
                    }

                    .details label {
                        font-size: 14px;
                    }

                    .trend {
                        margin-right: 10px;
                    }
                }

                @media only screen and (max-width: 600px) {
                    .chart-container {
                        /*padding: 0 0 120px;*/
                        padding: 0;
                    }

                    .position {
                        font-size: 25px;
                    }

                    footer {
                        /*display: block;*/
                    }

                    .add-this-header {
                        display: none;
                    }

                    .add-this-footer {
                        display: block;
                    }

                    .real-time {
                        display: none;
                    }

                    .app-logo {
                        text-align: center;
                    }

                    .details {
                        display: none;
                    }

                    .track {
                        border-radius: 0;
                        margin-bottom: 1px;
                    }
                }

                @media only screen and (max-width: 414px) {
                    .song {
                        margin: 10px;
                        margin-left: 20px;
                        margin-right: 20px;
                        flex: 3;
                    }

                    .artist, .title {
                        font-size: 14px;
                    }

                    .label {
                        font-size: 10px;
                    }

                    .details {
                        margin: 0 10px;
                    }

                    .details label {
                        width: 25px;
                    }

                    .trend {
                        margin-left: -10px;
                        margin-right: 20px;
                    }

                    footer {
                        padding: 10px 20px;
                    }
                }
            </style>

            <${DozRouter} mode="history" d:id="router">
                <${EarOne} preserve route="/earone/:page"/>
                <${EarOne} preserve route="/earone/*"/>
                <${RadioItalia} preserve route="/radio-italia/:page"/>
                <${RadioItalia} preserve route="/radio-italia/*"/>
                <${TvSorrisiECanzoni} preserve route="/tv-sorrisi-e-canzoni/:page"/>
                <${TvSorrisiECanzoni} preserve route="/tv-sorrisi-e-canzoni/*"/>
                <${EarOne} preserve route="*"/>
            </>
            
            <!--div>
                <h1>Arrivederci al 2024! 😀</h1>
            </div-->
        `
    },

    onCreate() {
        // Every time a component is mounted on the DOM,
        // I update the list of links mapped with the "data-router-link" attribute
        /*this.app.on('componentMountAsync', () => {
            if (this.router) {
                this.router.bindLink();
            }
        });*/
    },

    onMountAsync() {
        if (window.SSR) {
            window.SSR.ready();
        } else {
            setTimeout(() => {
                this.fixChartContainerHeight();
                window.addEventListener('resize', () => {
                    this.fixChartContainerHeight();
                })
            }, 1000);

        }
    },

    fixChartContainerHeight() {
        let headerHeight = document.getElementsByTagName('header')[0].getBoundingClientRect().height;
        let footerHeight = document.getElementsByTagName('footer')[0].getBoundingClientRect().height;
        document.getElementsByClassName('chart-container')[0].style.height = `calc(100vh - ${headerHeight + footerHeight}px)`;
    }
});