import {Component} from 'doz'
import AppHeader from '../../app-header'
import AppLogo from '../../app-logo'
import RealTime from '../../real-time'
import ChartTracks from '../chart-tracks'
import 'particles.js'
import particleJSConfig from './particlesjs-config-2'
import '../../x-track/x-track-details'
import '../../x-artwork-background'
import tvSorrisiECanzoni from './logo-tv-sorrisi-e-canzoni.png'

export default class TvSorrisiECanzoni extends Component {
    template(h) {
        return h`
            <style> 
                
                .track {
                    background: rgba(255,255,255,.90);
                }
                
                .artwork {
                    border: 3px solid #00000047;
                }
                
                footer {
                    justify-content: flex-end;
                }
                
                .earone-logo {
                    flex: 1;
                    text-align: left;
                }
                
                .earone-logo img{
                    width: 130px;
                }
                
                .radio-italia-logo{
                    width: 130px;
                }
                                
                @media only screen and (max-width: 600px) {
                    .earone-logo img{
                        width: 90px;
                    }
                    
                    .radio-italia-logo{
                        width: 90px;
                        margin-left: 20px;
                    }
                }
            </style>

            <main>
                <div d-ref="particles" id="particles-container"></div>
                <${AppHeader} d:alias="AppHeader" class="app-header" title="Sanremo 2022 in Radio" description="Classifica per punteggio dal 3 Febbraio 2022"> 
                    <!--div slot="col-right" class="charts-link">
                        <a data-router-link href="/tv-sorrisi-e-canzoni/campioni">Campioni</a> 
                        <a data-router-link href="/tv-sorrisi-e-canzoni/nuove-proposte">Nuove Proposte</a> 
                    </div-->
                </>
                <div class="chart-container">
                    ${this.pageCatcher()}
                </div>
                <footer> 
                    <${AppLogo} class="earone-logo" d:alias="AppLogo"/>
                    <img class="radio-italia-logo" src="${tvSorrisiECanzoni}">
                </footer>
                <x-track-details/>
            </main>
        `;
    }

    pageCatcher() {
        let page = this.router.param('page');
        switch (page) {
            case 'campioni':
                return this.h`
                    <${ChartTracks} 
                        source-url="https://api3.xdevel.com/earone/sanremo/big/radio/?clientId=2d3cb6b69df48fb23a33594ecf9fd0d871f7931d"
                    />`
            case 'nuove-proposte':
                return this.h`
                    <${ChartTracks} 
                        source-url="https://api3.xdevel.com/earone/sanremo/new/radio/?clientId=2d3cb6b69df48fb23a33594ecf9fd0d871f7931d"
                    />`
            default:
                return this.h`<div></div>`
        }
    }

    onMountAsync() {
        if (!this.router.param('page'))
            this.router.navigate('/tv-sorrisi-e-canzoni/campioni');

        if (!window.SSR) {
            setTimeout(() => {
                particlesJS('particles-container', particleJSConfig);
                this.animate(this.ref.particles, 'fadeIn');
            }, 3000);

            let linkActiveElement = document.getElementsByClassName('router-link-active')[0];
            if (linkActiveElement) {
                linkActiveElement.scrollIntoView();
            }
        }


    }
}