import {Component} from 'doz'
import request from '../helpers/request';

export default class RealTime extends Component {

    props = {
        artist: '-',
        title: '-',
        artwork: '',
        broadcasterName: '-',
        broadcasterLogoUrl: '',
        datePlay: ''
    };

    propsConvert = {
        datePlay(v) {
            if (!v) return '';
            return v.split(' ')[1].substr(0, 5);
        }
    };

    propsListener = {
        artwork(v) {
            this.loadim(v, () => {
                this.animate(this.ref.artwork, 'flipInX', {duration: '1000ms'});
            });
        }
    };

    template(h) {
        return h`
            <style> 
                :component {
                    flex: 1;    
                }
                
                .container {
                    text-align: left;
                    display: flex;
                    align-items: center;
                    color: black;
                }
                .artwork {
                    width: 100px;
                    height: 100px;
                    background: #ccc;
                    border-radius: 4px;
                    border: 2px solid #ccc;
                }
                .details {
                    margin-left: 20px;
                }
                
                .artist {
                    font-size: 18px;
                }
                
                .title {
                    margin-bottom: 10px;
                }
                
                @media only screen and (max-width: 800px) {
                    .artwork {
                        width: 70px;
                        height: 70px;
                    }
                    
                    .artist {
                        font-size: 14px;
                    }
                    
                    .title {
                        font-size: 14px;
                        margin-bottom: 8px;
                    }
                    
                    .details {
                        font-size: 14px;
                    }
                }
            </style>
            <div class="container">
                <img d-ref="artwork" class="artwork" style="visibility: ${this.props.artwork ? 'visible' : 'hidden'}" alt="${this.props.artist} - ${this.props.title}" src="${this.props.artwork}">
                <div class="details">
                    <div class="artist">${this.props.artist}</div>
                    <div class="title">${this.props.title}</div>
                    <div>Su <strong>${this.props.broadcasterName}</strong> alle ore <strong>${this.props.datePlay}</strong></div>
                </div>
            </div>
        `
    }

    onMount() {
        if (!this.isSSR())
            this.loadRequest();
    }

    loadRequest() {
        //return;
        //request('https://api3.xdevel.com/earone/lastplayon/?clientId=2d3cb6b69df48fb23a33594ecf9fd0d871f7931d&tag=41,42')
        request('https://api.xdevel.com/earone/lastplayon/?clientId=2d3cb6b69df48fb23a33594ecf9fd0d871f7931d&tag=49,52')
            .then(result => {
                result = result.data.result;

                let coverImage = /null\.jpg/g.test(result.track.artistPhotoUrl['400'])
                    ? result.track.artworkUrl['400']
                    : result.track.artistPhotoUrl['400'];

                this.props.artist = result.track.artist;
                this.props.title = result.track.title;
                this.props.artwork = coverImage;
                this.props.broadcasterName = result.broadcasterName;
                this.props.broadcasterLogoUrl = result.broadcasterLogoUrl;
                this.props.datePlay = result.datePlay;
            })
            .catch(err => {
                console.error(err);
            });
        setTimeout(() => this.loadRequest(), 15000);
    }

}