import {Component} from 'doz'

export default class AppLogo extends Component {
    template(h) {
        return h`
            <style> 
                :component {
                    display: block;
                    text-align: right;
                }
                
                .logo {
                    width: 180px;
                }
                
                .claim {
                    font-size: 12px;
                    color: #000;
                    margin: 10px 0;
                }
                
                @media only screen and (max-width: 800px) {
                    .logo {
                        width: 120px;
                    }
                    
                    .claim {
                        font-size: 10px;
                        margin: 0;
                    }
                }
                
                @media only screen and (max-width: 414px) {
                    .logo {
                        width: 70px;
                    }
                    
                    .claim {
                        font-size: 10px;
                        margin: 0;
                    }
                }
            </style>
            <a href="http://www.earone.it" target="_blank">
                <img alt="logo EarOne" class="logo" src="https://admuzzum.mediasender.it/site/earone/logo-earone-2024.svg">
            </a>
            <h3 class="claim">IL PARTNER DELL'INDUSTRIA MUSICALE ITALIANA</h3>
        `;
    }
}