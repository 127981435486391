import {Component} from 'doz'

export default class extends Component {
    template(h) {
        return h`
            <style> 
                .container-404 {
                    position: fixed;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }
            </style>
            <div class="container-404">
                <h1>404 Pagina non trovata...</h1>
            </div>
        `
    }
}